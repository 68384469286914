@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;700;700&display=swap');

.home {
  height: 100vh;
  margin-top: 0;
  padding: 130px;
  background-image: url('../../assets/img/grunge-brick-wall-background_1048-11975.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.neon-wrapper {
  width: auto;
  padding: 30px;
  margin: auto;
  border: 5px solid #fff;
  border-radius: 1.5rem;
  box-shadow: 
  1px 1px 10px rgba(255, 255, 255, .4),
  -1px -1px 10px rgba(255, 255, 255, .4),
  inset 1px 1px 10px rgba(255, 255, 255, .4),
  inset -1px -1px 10px rgba(255, 255, 255, .4);
  filter: blur(.3px);
}
.neon-text{
  font-family: 'Rajdhani', sans-serif;
  font-size: 4.75rem;
  font-weight: 400;
  text-align: center;
  color: #fff;
  text-shadow: 
  2px 2px 10px rgba(255, 255, 255, .4),
  3px 3px 20px rgba(255, 255, 255, .4),
  -2px -2px 10px rgba(255, 255, 255, .4),
  -3px -3px 10px rgba(255, 255, 255, .4);
  filter: blur(.3px);
  }
.neon-wrapper h2{
  font-size: 3rem;
}

@media (max-width: 850px){
  .home {
    height: 100vh;
  }
  
  .neon-wrapper {
    padding: 0;
  }
  .neon-wrapper h1{
    font-size: 4rem;
    margin: 40px 40px 0;
  }
  .neon-wrapper h2{
    font-size: 2.5rem;
    margin: 0 40px 40px;
  }
}
@media (max-width: 822px){
  .neon-wrapper h1{
    font-size: 3.5rem;
  }
  .neon-wrapper h2{
    font-size: 2.5rem;
  }
}
@media (max-width: 768px){
  .neon-wrapper {
    padding: 0;
  }
  .neon-wrapper h1{
    font-size: 3.5rem;
  }
  .neon-wrapper h2{
    font-size: 2.5rem;
  }
}
@media (max-width: 738px){
  .neon-wrapper {
    padding: 0;
  }
  .neon-wrapper h1{
    font-size: 3rem;
    margin: 35px 35px 0;
  }
  .neon-wrapper h2{
    font-size: 2rem;
    margin: 0 35px 35px;
  }

}
@media (max-width: 673px){
  .neon-wrapper {
    padding: 0;
  }
  .neon-wrapper h1{
    margin: 30px 30px 0;
    font-size: 2.5rem;
  }
  .neon-wrapper h2{
    margin: 0 30px 30px;
    font-size: 1.5rem;
  }
}
@media (max-width: 653px){
  .home{
    padding: 0px;
  }
  .neon-wrapper {
    width: 80%;
  }
  .neon-wrapper h1{
    margin: 20px 20px 0;
  }
  .neon-wrapper h2{
    margin: 0 20px 20px;
  }
}
@media (max-width: 468px){
  .neon-wrapper {
    width: 90%;
  }
}
@media (max-width: 420px){
  .home {
    height: 100vh;
  }
  .neon-wrapper {
    width: 90%;
  }
  .neon-wrapper h1{
    font-size: 2.2rem;
    margin: 25px 25px 5px;
  }
  .neon-wrapper h2{
    font-size: 1.5rem;
    margin: 0 20px 20px;
  }

}

  