* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
  background-blend-mode: multiply,multiply;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255,0.50); 
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  /* font-family: ; */
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}
@media (max-width: 420px){
  html{
    scroll-behavior: auto;
    scroll-padding-top: 0;
  }
}