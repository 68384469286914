footer {
    background-color: #121212;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
}
@media (max-width: 850px){
    footer{
        display: none;
    }
}