.project {
  padding: 10px 0;
  margin-top: 100px;
  position: relative;
  background: #151515;
}
.project h2 {
  text-align: center;
  font-size: 30px;
	font-weight: 400;
  opacity: 0.75;
  margin-bottom: 20px;
}
.project p {
  color: #fff;
  width: 70%;
  letter-spacing: 0.8px;
  margin: 14px auto 30px auto;
  text-align: center;
  font-weight: 400;
  font-family: 'Rajdhani', sans-serif;
  line-height: 1.5em;
  opacity: 0.75;
}
.project .nav.nav-pills {
  width: 60%;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  /* width: 25%; when it will be the 4th one */
  width: 33.3%;
  height: 40px;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  width: 100%;
  height: 40px;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 400;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
    background-blend-mode: multiply,multiply;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0; 
}
/* .nav-link#projects-tabs-tab-fourth {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
} */
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
.cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.paginationBtns{
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paginationBtns a{
  padding: 5px 10px;
  margin: 8px;
  border-radius: 5px;
  text-decoration: none;
  border: solid 1px whitesmoke;
  color: whitesmoke;
  cursor: pointer;
}
.paginationBtns a:hover{
  background-color: rgba(255, 255, 255, 0.5);
}
.paginationActive a{
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 1024px){
  .project {
    display: flex;
    justify-content: center;
  }
  .project p {
    width: 80%;
    margin: 30 auto;
    text-align: center;
  }
}

@media (max-width: 768px){
  .project p {
    width: 90%;
    text-align: start;
  }
  .project .nav.nav-pills {
    width: 100%;
  }
}

@media (max-width: 420px){
  .project h2 {
    padding-top: 70px;
  }
}


