.contact {
  height: 80vh;
  padding: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/img/grunge-brick-wall-background_1048-11975.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact h2 {
  text-align: center;
  font-size: 30px;
	font-weight: 400;
  margin-bottom: 25px;
  margin-top: 15px;
  opacity: 0.75;
}
.contact form {
  width: 40%;
  margin: 10px auto;
  padding: 20px;
  background-color: #151515;
  border-radius: 10px;
  border: 0.5px solid rgba(255, 255, 255, 0.05);
  box-shadow: 2px 2px 20px black;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  color: #fff;
  margin:10px 0px;
  padding: 10px;
  font-weight: 400;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form textarea {
  height: 100px;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  font-weight: 500;
  opacity: 0.8;
}
.contact form button {
  font-weight: 500;
  color: #000;
  background-color: #fff;
  padding: 5px 30px;
  border-radius: 30px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
  background-blend-mode: multiply,multiply;
  width: 0;
  height: 100%;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

@media (max-width: 1024px){
  .contact h2 {
    margin-top:0;
    margin-bottom: 10px;
  }
}
@media (max-width: 991px){
  .contact h2 {
    margin-top:30px;
  }
  .contact{
    height: 100vh;
  }
  .contact form {
    width: 60%;
  }
}

@media (max-width: 768px){
  .contact {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact h2 {
    padding-top: 50px;
  }
  .contact form {
    width: 100%;
    margin: 10px auto;
    padding: 15px;
  }
  .contact form textarea {
    height: 100px;
  }
}

@media (max-width: 420px){
  .contact h2 {
    margin-top: 0;
    padding-top: 0;
  }
  .contact form {
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }
  .contact form textarea {
    height: 90px;
  }
}