@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

.skills {
  height: 50vh;
  padding: 0;
  position: relative;
  margin-bottom: 10px;
}
.skill--box {
  background: #151515;
  border-radius: 30px;
  text-align: center;
  padding: 30px 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.skill--box h2 {
  font-size: 30px;
	font-weight: 400;
  opacity: 0.75;
  margin-bottom: 20px;
}
.skill--box p {
  color: #fff;
  font-weight: 400;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  opacity: 0.75;
  margin-bottom: 30px;
}
.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item{
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill-slider .item .techIcons {
  width: 70%;
}
.tech-name{
  margin-top: 5px;
  font-family: 'Rajdhani', sans-serif;
  text-align: center;
  font-weight: 400;
}
.background-image-right{
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
@media (max-width: 1024px){
  .skills {
    height: 70vh;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px){
  .skill--box {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .skill--box h2 {
    margin: 20px;
  }
  .skill--box p {
    width: 100%;
    margin-bottom: 40px;
  }
  .skill-slider .item{
    width: 140px;
    height: 140px;
  }
  .tech-name{
    margin-top: 15px;
  }
  .background-image-right{
    display: none;
  }
}

@media (max-width: 420px){
  .skills {
    height: 70vh;
    margin-bottom: 100px;
  }
  .skill--box {
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 0;
  }
  .skill--box h2 {
    margin: 20px;
  }
  .skill--box p {
    width: 100%;
    margin-bottom: 40px;
  }
  .skill-slider .item{
    width: 150px;
    height: 150px;
  }
  .tech-name{
    margin-top: 10px;
  }
  .background-image-right{
    display: none;
  }
}
