@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
.about {
  padding: 0 0 50px 0;
  position: relative;
}
.about--box {
  background: #151515;
  border-radius: 30px;
  text-align: center;
  padding: 20px 50px;
  margin-top: -30px;
  margin-bottom: 20px;
}
.about--box h2 {
	font-size: 30px;
	font-weight: 400;
  opacity: 0.75;
  margin-bottom: 30px;
}

.about h3 {
  font-size: 30px;
  font-weight: 400;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 0.8px;
  line-height: 1;
  opacity: 0.75;
  display: block;
}
.aling-items-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about p {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 100%;
  opacity: 0.75;
  text-align: start;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.download-btn{
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  color: #fff;
  opacity: 0.75;
  margin-top: 30px;
  border: solid 1px;
  padding: 5px 10px;
  border-radius: 10px;
}
.download-btn:hover{
  color: #000;
  background-color: #fff;
  opacity: 1;
}
@media (max-width: 768px){
  .about {
    padding:0;
    display: flex;
    justify-content: center;
  }
  .about--box h2 {
    padding-top: 35px;
    margin-bottom: 25px;
  }
  
  .download-btn{
    margin: 20px;
 
  }
  .download-btn:hover{
    color: #000;
    background-color: #fff;
    opacity: 1;
  }
  .display{
    display: none;
  }
}  
@media (max-width: 420px){
  .about--box {
    background-color: #151515;
    padding: 0 5px 5px 20px;
    margin-bottom: 0;
  }
  .about--box h2 {
    font-size: 30px;
    font-weight: 400;
    opacity: 0.75;
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .about p {
    margin-bottom: 0;
  }
  
  .background-image-left {
    display: none;
  }
  .download-btn{
    margin-top: 25px;
    padding: 5px 10px;
    margin-bottom: 20px;
  }
  .display{
    display: none;
  }
  
}
